import { createContext, useRef } from 'react';

/**
 * React context allows us to pass down and use (consume)
 * data in whatever component we need in our React app
 * without using props. In other words, React context
 * allows us to share data (state) across our components
 * more easily.
 *
 * @example
 * import { useContext } from 'react';
 * import { Context } from '../contexts/MainContext';
 *
 * export default function Hero() {
 *   const { setContactModalState } = useContext(Context);
 *   ...
 */
export const Context = createContext();
export default function MainContext(props) {

  const chatRef = useRef(null);
  function scrollToBottom() {
    if (chatRef.current) {
      chatRef.current.scrollTo(0, Number.MAX_SAFE_INTEGER);
    }
  }

  // Include all variables here
  const globalVariables = {
    chatRef,
    scrollToBottom,
  };

  return (
    <Context.Provider value={globalVariables}>
      {props.children}
    </Context.Provider>
  );
}
