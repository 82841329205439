/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import roomsData from '../assets/data/rooms.json';
import styles from './Chat.module.scss';
import Message from './Message';

export default function Chat() {
  const MESSAGES_LENGTH = 2;
  const [messages, setMessages] = useState([...Array(MESSAGES_LENGTH).keys()].reverse());
  const [roomDescription, setRoomDescription] = useState(roomsData[0]['description']);
  const [roomName, setRoomName] = useState(roomsData[0]['name']);
  const navigate = useNavigate();
  const params = useParams();

  function handleChange(event) {
    const value = event.target.value;
    setMessages([...messages, value]);
  }

  // Change community or rooms
  useEffect(() => {
    const communityId = parseInt(params.communityId);
    const roomId = parseInt(params.roomId);

    // `/community/01/02` --> goes to `/community/1/2`
    if (communityId.toString() !== params.communityId || roomId.toString() !== params.roomId) {
      navigate(`/communities/${communityId}/${roomId}`);
      return null;
    }

    // When community or room doesn't exist, go to `/community/0/0`
    if (!roomsData[communityId] || !roomsData[roomId]) {
      navigate(`/communities/0/0`);
      return null;
    }

    console.log(communityId, roomId, roomsData[roomId]['name']);
    setRoomName(roomsData[roomId]['name']);
    setRoomDescription(roomsData[roomId]['description']);

    if (messages[0] === 0) {
      setMessages(([...Array(MESSAGES_LENGTH).keys()].reverse()));
    } else {
      setMessages(([...Array(20).keys()]));
    }
  }, [params.communityId, params.roomId]);

  return (
    <div className={styles.base}>

      <div className={styles.communities}>
        {roomsData.map((community) => {
          const isActive = community.id === parseInt(params.communityId) ? true : false;
          return (
            <div
              className={clsx(
                styles.communities__item,
                { [styles.communities__item__active]: isActive }
              )}
              data-id={community.id}
              key={community.id}
              onClick={() => !isActive && navigate(`/communities/${community.id}/${0}`)}
            ></div>
          );
        })}
      </div>

      <div className={styles.rooms}>
        <div className={styles.rooms__rooms}>
          <p className={styles.rooms__heading}>KPop City</p>
          {roomsData.map((room) => {
            const isActive = room.id === parseInt(params.roomId) ? true : false;
            return (
              <div
                className={clsx(
                  styles.rooms__item,
                  { [styles.rooms__item__active]: isActive }
                )}
                key={room.id}
                onClick={() => navigate(`/communities/${params.communityId}/${room.id}`)}
              ># {room.name}</div>
            );
          })}
        </div>
      </div>

      <div className={styles.center}>
        {/* Top */}
        <div className={styles.chat__top}># {roomName}
          {roomDescription && <span>&nbsp; - {roomDescription}</span>}
        </div>

        {/* Center */}
        <div className={styles.chat__center}>
          <div className={styles.chat__messages}>
            {messages.map((data, index) => {
              return (
                <Message data={data} key={index} />
              );
            })}
          </div>
        </div>

        {/* Bottom */}
        <div className={styles.chat__bottom}>
          <textarea onChange={handleChange}></textarea>
        </div>
      </div>

      <div className={styles.members}></div>
    </div>
  );
}
