/* eslint-disable react-hooks/exhaustive-deps */
import { faker } from '@faker-js/faker';
import Avatar from 'boring-avatars';
import { getLinkPreview as getLinkPreviewData } from 'link-preview-js';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Chat.module.scss';

export default function Message(props) {
  const [getLinkPreview, setLinkPreview] = useState(null);
  const [isImageActive, setImageState] = useState(false);
  const content = useRef(faker.hacker.phrase());
  const imageSource = useRef(faker.image.imageUrl());
  const timestamp = useRef(Date.now());
  const username = useRef(faker.name.findName());
  const [isProfilePopoutActivated, setProfilePopoutState] = useState(false);
  const [isProfilePopoutReference, setProfilePopoutReference] = useState(null);
  // console.log('RERENDER', props.data);

  // On load
  useEffect(() => {
    // Link preview
    getLinkPreviewData(props.data)
      .then(data => {
        setLinkPreview(data);
      })
      .catch(error => {
        console.debug(error);
      });
  }, []);

  function handleClickProfile(event) {
    setProfilePopoutReference(event.target);
    setProfilePopoutState(!isProfilePopoutActivated);
  }

  return (
    <div className={styles.message}>
      <div className={styles.message__left}>
        <div className={styles.message__avatar} onClick={handleClickProfile}>
          <div className={styles.message__avatar__inner}>
            <Avatar name={username.current} size={38} square={true} />;
          </div>
        </div>
      </div>
      <div className={styles.message__right}>
        <div className={styles.message__infomation}>
          <span className={styles.message__username} onClick={handleClickProfile}>{username.current}</span>
          <time className={styles.message__timestamp} dateTime={new Date(timestamp.current).toISOString()}>{new Date(timestamp.current).toString().substring(4, 15)}</time>
        </div>
        <p className={styles.message__content}>{props.data} -- {content.current}
          {/* <button className={styles.message__button} onClick={() => setImageState(!isImageActive)}>Add image</button> */}
        </p>
        {isImageActive &&
          <img
            alt="placeholder"
            className={styles.message__image}
            loading="lazy"
            src={imageSource.current}
          />
        }

        {getLinkPreview !== null &&
          <div className={styles.message__preview} >
            <p>{getLinkPreview.description}</p>
            <p>{getLinkPreview.favicons[0]}</p>
            <img alt={getLinkPreview.title} src={getLinkPreview.images[0]} />
            <p>{getLinkPreview.title}</p>
            <p>{getLinkPreview.url}</p>
          </div>
        }

        {isProfilePopoutActivated &&
          <ProfilePopout
            name={username.current}
            reference={isProfilePopoutReference}
            setProfilePopoutState={setProfilePopoutState}
          />
        }

      </div>
    </div>
  );
}

// const MemoizedMessage = React.memo(Message);
// export default MemoizedMessage;

// Profile popover
function ProfilePopout(props) {
  const dimensions = useState(props.reference.getBoundingClientRect())[0];
  const profilePopoutRef = useRef(null);
  const OFFSET_X = 8;

  // Handles click events
  useEffect(() => {
    function handleClick(event) {
      const target = event.target;
      if (props.reference.contains(target)) {
        // If clicked on reference, do nothing
        // Allows for togglable popover
        return null;
      } else if (!profilePopoutRef.current.contains(target)) {
        // If clicked outside of popover, close it
        props.setProfilePopoutState(false);
      }
    }

    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  }, []);

  return createPortal(
    <div
      className={styles.profile_popout}
      ref={profilePopoutRef}
      style={{
        position: 'fixed',
        top: `${dimensions.top}px`,
        left: `${dimensions.left + dimensions.width + OFFSET_X}px`,
      }}
    >{props.name}</div>,
    document.getElementById('portal')
  );
};
