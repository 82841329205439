import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat';
import MainContext from './contexts/MainContext';

export default function App() {
  return (
    <MainContext>
      <BrowserRouter>
        <Routes>
          <Route path="communities" element={<Chat />}>
            <Route path=":communityId" element={<Chat />}>
              <Route path=":roomId" element={<Chat />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate replace to="/communities/0/0" />} />
        </Routes>
      </BrowserRouter>
    </MainContext>
  );
}
